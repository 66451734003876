import { SET_EMPLOYEE_DATA, EMPLOYEE_UPDATE_CREATE } from '../actions/EmployeeActions';

const initialState = {};

const employeeReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case EMPLOYEE_UPDATE_CREATE: {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default employeeReducer;
