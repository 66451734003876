export const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA';
export const EMPLOYEE_UPDATE_CREATE = 'EMPLOYEE_UPDATE_CREATE';

export function setEmployeeDataToReducer(employeeData) {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEE_DATA,
      data: employeeData,
    });
  };
}

export function updateCreateEmployeeToReducer(data) {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEE_UPDATE_CREATE,
      data: data,
    });
  };
}
